<template>
  <div class="ym-table"
       style="width: 100%">

    <!-- 表头操作栏,用于放置功能按钮 -->
      <div class="ym-table-header" v-if="headData && Object.keys(headData).length">
        <!-- 左侧标题 -->
        <div class="ym-table-header-left">{{headData.title}}</div>
        <!-- 右侧功能区 -->
        <div class="ym-table-header-right">
          <component class="table-btn" :class="'btn-'+i"  v-for="(item,i) in headData.items" :key="i"
            :is="item.style+'Icon'"
            :tableData="data"
            :columns="columns"
            :item="item"
            >
          </component>
        </div>
      </div>

    <!-- 列表主体 -->
    <el-table :class="['ym-table-body', {'no-pagination': !hasPagination}]"
              v-loading="loading"
              v-bind="[$attrs,tableOption]"
              v-on="$listeners"
              :ref="id"
              :data="data"
              @selection-change="handleSelectionChange">
      <template v-for="(col, colProp) in columnData">

        <!-- 操作列 -->
        <el-table-column v-bind="[col]"
                         :key="colProp"
                         v-if="col.prop === 'operators'">
          <template slot-scope="scope">
            <template v-for="(it, index) in col.children">
              <el-button class="ym-table--btn"
                         :key="index"
                         v-if="buttonControl(it.showFilter, it, scope.row)"
                         type="text"
                         :icon="it.icon"
                         :style="{color: it.color}"
                         @click="onclick(it.clickEvent, scope.row)">
                {{it.label}}
              </el-button>
            </template>
          </template>
        </el-table-column>

        <!-- 自定义列 -->
        <el-table-column v-bind="[col]"
                         :key="colProp"
                         v-else-if="col.enableSlot">
          <template slot-scope="scope">
            <slot :name="col.prop"
                  :row="scope.row"></slot>
          </template>
        </el-table-column>

        <!-- 文本类列表数据 -->
        <el-table-column v-else-if="col.show"
                         v-bind="[col]"
                         :key="colProp">
          <template v-if="col.content">
            <div v-html="col.content"></div>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!-- 底部分页栏 -->
    <div class="ym-table-bottom"
         v-if="hasPagination">
      <YmPagination :pageData="pageData"
                    @handlePageChange="handlePageChange"
                    @handleSizeChange="handleSizeChange"></YmPagination>
    </div>

  </div>
</template>

<script>
import YmPagination from './YmPagination'
export default {
  props: {
    // 显示的数据
    data: {
      type: Array,
      default: () => []
    },
    // 表格属性
    tableOption: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 表格列属性
    columns: {
      type: Array,
      default: () => []
    },
    // 表头属性配置
    headData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // ref
    id: {
      type: String,
      default: 'multipleTable'
    },
    // 列表数据
    pageData: {
      type: Object,
      default: () => {}
    },
    // 加载动画
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columnData: [],
      tableRef: null,
      mode: {},
      checked: false,
      checkeds: [],
      selection: [], // 已选的所有数据内容
      paginationOptions: {}, // 备份分页配置
      flag: false,
      isIndeterminate: false,
      hasPagination: false
    }
  },
  components: {
    YmPagination,

    // 使用components的is属性进行动态加载
    buttonIcon: () => import('./HeadIcon/buttonIcon'),
    iconIcon: () => import('./HeadIcon/iconIcon'),
    dropdownIcon: () => import('./HeadIcon/dropdownIcon')
  },

  watch: {
    data: {
      handler(val) {
        this.updateChecked()
      },
      deep: true
    },
    columns: {
      handler(val) {
        this.initColumns()
      },
      immediate: true,
      deep: true
    },

    checkeds(val) {
      this.updateChecked()
    },
    pageData: {
      handler(val) {
        this.hasPagination = this.checkPagination()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 控制按钮显隐
    buttonControl(showFilter, item, row) {
      if (showFilter && typeof showFilter === 'function') {
        return showFilter(item, row, this)
      }
      return true
    },
    // selectCol为true时，存储所有选择项
    handleSelectionChange(selection) {
      this.selection = selection
      this.$emit('selectChange', this.selection)
    },
    // 全选
    toggleAllSelection() {
      this.$refs[this.id].toggleAllSelection()
    },
    // 点击页码跳转页面
    handlePageChange(goPage) {
      this.$emit('handlePageChange', goPage)
    },
    // 点击配置分页条数
    handleSizeChange(number) {
      this.$emit('handleSizeChange', number)
    },
    // 更新全选框状态
    updateChecked() {
      if (this.pageData && this.pageData.currentPage) {
        this.checked =
          this.checkeds[this.pageData.currentPage - 1] === this.data.length
        this.isIndeterminate =
          !this.checked &&
          ((this.checkeds[this.pageData.currentPage - 1] > 0 &&
            this.checkeds[this.pageData.currentPage - 1] < this.data.length) ||
            this.selection.length > 0)
      }
    },
    // 初始化列表项
    initColumns() {
      let width = window.innerWidth
      this.columnData = this.columns.filter(
        (item) =>
          item.show !== false && (!item.minScreen || width >= item.minScreen)
      )
    },
    // 检查参数是否为方法
    checkEvent(fun, item) {
      if (fun && typeof fun === 'function') {
        fun(item)
      }
      this.initColumns()
    },
    onclick(fun, row) {
      if (fun && typeof fun === 'function') {
        fun(this.$parent, row)
      }
    },
    // 查询是否具有分页
    checkPagination() {
      return this.showPagination || (this.pageData && Object.keys(this.pageData).length && this.pageData.total && this.pageData.size && (this.pageData.total > this.pageData.size))
    }
  }
}
</script>

<style lang="scss">
.ym-table {
  min-width: 440px;
  // border: $--header-border;
  border-radius: $--border-radius-base;
  background-color: $--main-bg-color;
  overflow: hidden;
  color: $--color-text-primary;
  // .ym-table--btn {
  //   padding: 0 4px;
  // }

  /**** 表头样式 ****/
  .ym-table-header {
    position: relative;
    width: 100%;
    padding: 16px 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      letter-spacing: 2px;
    }

    .ym-table-header-left{
      float: left;
    }

    .ym-table-header-right {
      float: right;
      .iconfont {
        font-size: 16px;
        color: $--color-text-primary;
        margin-left: 20px;
      }
      .el-button span {
        margin-left: 8px;
      }
    }
  }

  /**** 表格主体样式 ****/
  .el-table .el-table__header {
    th {
      background: #e3e5e8;
      &:last-child {
        padding: 12px 24px;
      }
      &:not(:last-child) {
        padding: 12px 0 12px 24px;
      }
    }
  }
  .el-table--medium th, .el-table--medium {
    font-size: $--font-size-base;
      td {
      &:last-child {
        padding: 10px 24px;
      }
      &:not(:last-child) {
        padding: 10px 0 10px 20px;
      }
    }
  }
 .el-table--mini th, .el-table--mini {
    font-size: $--font-size-base;
      td {
      &:last-child {
        padding: 4px 20px;
      }
      &:not(:last-child) {
        padding: 4px 0 4px 24px;
      }
    }
  }
  .ym-table-body{
    .el-button--text {
      position: relative;
      color: $--color-primary;
      transition: all 0.2s;
      font-weight: normal;
      // &::after {
      //   position: absolute;
      //   content: '';
      //   display: block;
      //   width: 0;
      //   height: 1px;
      //   background-color: $--color-text-primary;
      // }
      // &:hover {
      //     &::after {
      //       width: 100%;
      //   }
      // }
      i {
        font-size: 14px;
      }
      i+span{
        margin-left: 0;
      }
    }
    .cell {
      .el-button+.el-button {
        margin-left: 0px;
        &::before {
          padding: 0 4px;
          color: #EBEDF0;
          content: '|';
        }
      }
    }
    .hover-row {
      background-color: $--color-primary-light-9;
    }
  }

  .ym-table-body.no-pagination {
    &::before {
      height: 0;
    }
    .el-table__row:last-child {
      td {
        border-bottom: none;
      }
    }
    .el-table__fixed-right::before {
        height: 0;
    }
  }

  /**** 表格底部样式 ****/
  .ym-table-bottom {
    padding: 24px 32px;
    position: relative;
    height: 70px;
  }
}
</style>
