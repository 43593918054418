<template>
  <div class="ym-Pagination">
    <el-pagination  v-bind="[pageData]"  @current-change="handlePageChange" @size-change="handleSizeChange">
      <el-button size="medium" type="text">跳转 <i class="ym-icon-tiaozhuan-01 el-icon--right"></i> </el-button>
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handlePageChange(goPage) {
      console.log('子组件触发')
      this.$emit('handlePageChange', goPage)
    },
    handleSizeChange(number) {
      console.log('子组件触发')
      this.$emit('handleSizeChange', number)
    }
  }
}
</script>

<style lang="scss" scoped>
.ym-Pagination{
  float: right;
  /deep/.el-pagination {
    span{
      margin-left: 16px;
    }
    .el-button--text i {
      font-size: 12px;
      transform: scale(0.8);
    }
  }
}
</style>
