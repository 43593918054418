<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-width="100px"
             label-position="right">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="状态ID">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.id"
                      clearable
                      placeholder="请输入状态ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="状态数据">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.json"
                      clearable
                      placeholder="请输入状态数据"></el-input>
          </el-form-item>
        </el-col>
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
        <!-- <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="最新修改记录">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.parentHash"
                      clearable
                      placeholder="请输入最新修改记录"></el-input>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="状态版本">
            <el-input class="inputWid"
                      @keyup.enter.native="confirm"
                      v-model="form.statusVersion"
                      clearable
                      type="number"
                      placeholder="请输入状态版本"></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <!-- <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    rest() {
      this.form.id = ''
      this.form.json = ''
      this.form.parentHash = ''
      this.form.statusVersion = ''
      this.confirm()
    },
    confirm() {
      let _form = {
        id: this.form.id ? this.trim(this.form.id) : '',
        json: this.form.json ? this.trim(this.form.json) : '',
        parentHash: this.form.parentHash ? this.trim(this.form.parentHash) : '',
        statusVersion: this.form.statusVersion ? this.trim(this.form.statusVersion) : ''
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
