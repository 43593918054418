import dayjs from 'dayjs'
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
/**
 * 时间转年月
 * @param {Number|String} time 输入时间,可以是时间戳(毫秒),'YYYY-MM-DD','YYYY/MM/DD'或者'YYYY年MM月DD日'格式的日期
 * @param {String} format 指定输出时间格式
 * @return {String} 返回格式化的时间
 */
const timeFormat = function (time, format) {
  let t = time
  if (typeof t === 'string' && (t.includes('年') || t.includes('月') || t.includes('日') || t.includes('时') || t.includes('分') || t.includes('秒'))) {
    t = handleCNTime(t)
  }
  return dayjs(t).format(format)
}

// 处理中文日期
const handleCNTime = function (time) {
  let checkRes = includeCheck(time)
  // 处理传入的中文日期
  let i = 0 // 记录有效数据的个数
  let arr = time
  let word = ['年', '月', '日', '时', '分', '秒']
  let result = []
  do {
    // 通过字符串分割出年月日时分秒
    arr = arr.split(word[i])
    if (i === 0) {
      result.push(checkRes[i] ? handleYear(arr[0].trim()) : '2001')
    } else if (i < 3) {
      // 将月日补全为两位数，无输入数据则默认为'01'
      result.push(checkRes[i] ? (arr[0].trim().length < 2 ? '0' + arr[0].trim() : arr[0].trim()) : '01')
    } else {
      // 将月日补全为两位数，无输入数据则默认为'00'
      result.push(checkRes[i] ? (arr[0].trim().length < 2 ? '0' + arr[0].trim() : arr[0].trim()) : '00')
    }
    arr = checkRes[i] ? arr[1] : arr[0]
    i++
  } while (arr)

  for (; i > 0; i--) {
    // 为传入的数据补零
    result.push('00')
  }
  return `${result[0]}-${result[1]}-${result[2]} ${result[3]}:${result[4]}:${result[5]}`
}

// 检查日期数据是否齐全
const includeCheck = function (t) {
  let word = ['年', '月', '日', '时', '分', '秒']
  let res = []
  for (let i in word) {
    res.push(t.includes(word[i]))
  }
  return res
}

// 处理年份，暂时以2000年为基准
const handleYear = function (y) {
  let len = y.length
  let year
  if (len === 1) {
    year = '200' + y
  } else if (len === 2) {
    year = '20' + y
  } else if (len === 3) {
    year = '2' + y
  } else {
    year = y
  }
  return year
}

export default timeFormat
